import * as React from "react";
import Container from "../components/container";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import {
  mainContent,
  // description,
  row,
  //col6,
  col12,
  marginTopSubstitute,
  introSection,
} from "./subpage.module.scss";

const ProzessmanagerPage = () => {
  return (
    <Layout pageTitle="Prozessmanager" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
        <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                {/* <p className={description}></p> */}
                <h1>Prozessmanager</h1>
                <p className="text-medium">
                  Verarbeitungsprozesse können definierte Regeln zwischen den
                  einzelnen Prozessschritten enthalten. Die Objekte nehmen dann
                  je nach Bedingung einen entsprechenden Verarbeitungszweig. 
                  </p>
                  <p className="text-medium">Die
                  Definition von parallelen Prozessen ist möglich. Die Plattform
                  gewährleistet einen durchgängig konsistenten Zustand bei
                  paralleler Verarbeitung. Für Benutzer werden
                  prozessspezifische individuelle ToDo-Listen erstellt. Für
                  Arbeitsgruppen werden Gruppenlisten geführt.
                  Benachrichtigungsmechanismen informieren über E-Mail, SMS,
                  etc.
                </p>
              </div>
            </div>

            <div className={row}>
              <div className={col12}>
                <h3>Prozess Designer</h3>
                <p>
                  Mit dem Prozess Designer kann ein entsprechender Prozess
                  mittels Drag & Drop definiert werden. Nach der
                  Prozessdefinition werden nur noch einzelne Schritte des
                  Prozesses parametrisiert. Dies erfolgt via Doppelklick auf
                  einen Prozessschritt. Programmierung ist nicht mehr notwendig.
                </p>
                <StaticImage
                  src="../assets/prozess.png"
                  alt=""
                  placeholder="none"
                  layout="fullWidth"
                  formats={["auto", "webp", "avif"]}
                />

                <div className={marginTopSubstitute}>
                  <h3>Archiv</h3>
                  <p>
                    Der Archiv Prozess steuert alle Funktionen im Zusammenhang
                    mit der Archivierung. Dabei können im Prozess Regeln
                    definiert werden, die abhängig vom Dokumententyp, von
                    Patienten- oder Fallinformationen Konvertierung, Signierung
                    und Archivierungsfristen steuern.
                  </p>
                </div>

                <div className={marginTopSubstitute}>
                  <h3>Konverter</h3>
                  <p>
                    Dieser Service kann bei Dokumenten, welche in einem nicht
                    archivtauglichen Format vorliegen, aufgeschaltet werden.
                  </p>
                </div>

                <div className={marginTopSubstitute}>
                  <h3>Signatur Service</h3>
                  <p>
                    Dieser Service signiert ein Objekt (Dokument oder ganzes
                    Paket) und zeigt mit einem intakten Siegel, dass das Objekt
                    nicht verändert wurde.
                  </p>
                </div>

                <div className={marginTopSubstitute}>
                  <h3>Speicher</h3>
                  <p>
                    Dieser Prozess steuert den Speicherort von Objekten in
                    Abhängigkeit von zeitlichen und anderen Vorgaben.
                  </p>
                </div>
              </div>

          
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default ProzessmanagerPage;
